// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Customform3 from "../../blocks/customform3/src/Customform3";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Documentstorage from "../../blocks/documentstorage/src/Documentstorage";
import Contentmanagement3 from "../../blocks/contentmanagement3/src/Contentmanagement3";
import Cfmanagingmanagementfees from "../../blocks/cfmanagingmanagementfees/src/Cfmanagingmanagementfees";
import Cfmeetings2 from "../../blocks/cfmeetings2/src/Cfmeetings2";
import Notifications from "../../blocks/notifications/src/Notifications";
import UserStatus from "../../blocks/userstatus/src/UserStatus";
import Formapprovalworkflow from "../../blocks/formapprovalworkflow/src/Formapprovalworkflow";
import Cfsuggestion2 from "../../blocks/cfsuggestion2/src/Cfsuggestion2";
import Cfclickableworldmap from "../../blocks/cfclickableworldmap/src/Cfclickableworldmap";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Ticketsystem2 from "../../blocks/ticketsystem2/src/Ticketsystem2";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import Organisationhierarchy from "../../blocks/organisationhierarchy/src/Organisationhierarchy";
import Search from "../../blocks/search/src/Search";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Automaticreminders from "../../blocks/automaticreminders/src/Automaticreminders";
import Reservations2 from "../../blocks/reservations2/src/Reservations2";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import Projecttemplates from "../../blocks/projecttemplates/src/Projecttemplates";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import Polling from "../../blocks/polling/src/Polling";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Analytics from "../../blocks/analytics/src/Analytics";



const routeMap = {
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Customform3:{
 component:Customform3,
path:"/Customform3"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Documentstorage:{
 component:Documentstorage,
path:"/Documentstorage"},
Contentmanagement3:{
 component:Contentmanagement3,
path:"/Contentmanagement3"},
Cfmanagingmanagementfees:{
 component:Cfmanagingmanagementfees,
path:"/Cfmanagingmanagementfees"},
Cfmeetings2:{
 component:Cfmeetings2,
path:"/Cfmeetings2"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
UserStatus:{
 component:UserStatus,
path:"/UserStatus"},
Formapprovalworkflow:{
 component:Formapprovalworkflow,
path:"/Formapprovalworkflow"},
Cfsuggestion2:{
 component:Cfsuggestion2,
path:"/Cfsuggestion2"},
Cfclickableworldmap:{
 component:Cfclickableworldmap,
path:"/Cfclickableworldmap"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
Ticketsystem2:{
 component:Ticketsystem2,
path:"/Ticketsystem2"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Rolesandpermissions:{
 component:Rolesandpermissions,
path:"/Rolesandpermissions"},
Organisationhierarchy:{
 component:Organisationhierarchy,
path:"/Organisationhierarchy"},
Search:{
 component:Search,
path:"/Search"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
Automaticreminders:{
 component:Automaticreminders,
path:"/Automaticreminders"},
Reservations2:{
 component:Reservations2,
path:"/Reservations2"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
Projecttemplates:{
 component:Projecttemplates,
path:"/Projecttemplates"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
Polling:{
 component:Polling,
path:"/Polling"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Analytics:{
 component:Analytics,
path:"/Analytics"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;